@tailwind base;
@tailwind components;
@tailwind utilities;

.rtl {
  direction: rtl;
  text-align: right;
}
.ltr {
  direction: ltr;
  text-align: left;
}

body {
  margin: 0; /* Elimina márgenes por defecto */
  padding: 0; /* Elimina paddings por defecto */
  width: 100%; /* Asegúrate de que el body ocupe el 100% del ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
  box-sizing: border-box;
}

